import React from "react";
import loadable from "@loadable/component";
import {
    CATCHUP_SECTION_BLUR_WIDTH,
    CATCHUP_SECTION_BLUR_HEIGHT,
} from "@constants";
import { GLOBAL_APPLICATION_INTERNAL_LINKS } from "@links";
import {
    GlobalStyle,
    CatchUpSectionContainer,
} from "./DiscoveryAndPlanning.style";
import backgroundImage from "@images/discovery-and-planning/backgroundImage.jpg";
import DiscoveryFAQSection from "./components/FAQSection.js/FAQSection";
import { 
    SECOND_CATCH_UP_SECTION_TITLE, 
    SECOND_CATCH_UP_SECTION_DESCRIPTION, 
    FEEDBACK_SECTION_ITEMS
} from "./constants";

const VideoSection = loadable(() =>
    import("@common/CommonSections/VideoSection/VideoSection")
);

const TextTitleSection = loadable(() =>
    import("@common/CommonSections/TextTitleSection/TextTitleSection")
);

const WhyChooseSection = loadable(() =>
    import("./components/WhyChooseSection/WhyChooseSection")
);

const ResultsSection = loadable(() =>
    import("./components/ResultsSection/ResultsSection")
);

const ProcessSection = loadable(() =>
    import("./components/ProcessSection/ProcessSection")
);

const FeedbackSection = loadable(() => import("@common/SlideShow/ContentWithFeedback/FeedbacksSection/FeedbacksSection"));

const CircleBLur = loadable(() => import("@common/CircleBlur/CircleBlur"));

const CrawlableSecondCatchUpSection = loadable(() =>
    import("@common/CommonSections/CrawlableSecondCatchUpSection")
);

const LinkButton = loadable(() => import("@common/LinkButton"));

const DiscoveryAndPlanning = () => (
    <div className="discovery-and-planning">
        <GlobalStyle />
        <TextTitleSection
            title="Product discovery"
            description={`
                    During the product discovery phase,
                    our team will conduct a comprehensive analysis of your
                    idea and will turn it into a clear vision of your product
                    and an in-depth development plan. With the experienced and
                    motivated Impressit team, you will get a smooth and stressless
                    planning process that will help you to achieve all the business goals in the future.
                    Moreover, it will also teach you how to hire software developers that will fit your project perfectly.
                `}
            backgroundImage={backgroundImage}
        />
        <ProcessSection />
        <ResultsSection />
        <FeedbackSection
            feedbacks={FEEDBACK_SECTION_ITEMS}
            title="Our Clients say"
        />
        <WhyChooseSection />
        <DiscoveryFAQSection />
        <CatchUpSectionContainer>
            <CircleBLur
                width={CATCHUP_SECTION_BLUR_WIDTH}
                height={CATCHUP_SECTION_BLUR_HEIGHT}
            />
            <CrawlableSecondCatchUpSection
                title={SECOND_CATCH_UP_SECTION_TITLE}
                description={SECOND_CATCH_UP_SECTION_DESCRIPTION}
            >
                <LinkButton to={GLOBAL_APPLICATION_INTERNAL_LINKS.CONTACT_US}>
                 Contact us
                </LinkButton>
            </CrawlableSecondCatchUpSection>
        </CatchUpSectionContainer>
    </div>
);

export default DiscoveryAndPlanning;
